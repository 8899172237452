import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private notificationIdSource = new BehaviorSubject<string | null>(null);
  notificationId$ = this.notificationIdSource.asObservable();

  setNotificationId(notificationId: string) {
    this.notificationIdSource.next(notificationId);
  }

}
