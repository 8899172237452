<form
  class="form-horizontal"
  autocomplete="off"
  #contactForm="ngForm">
  <div class="form-grid">
    <div class="input-label-cont extra form-grid-item" *ngIf="showEmail()">
      <label for="emailAddress" [ngClass]="!isViewMode ? 'required' : ''">{{ "EmailAddress" | localize }}</label>
      <div class="input-cont">
        <input
          type="email"
          class="input-txt"
          name="emailAddress"
          id="emailAddress"
          required
          maxlength="256"
          pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
          [disabled]="isViewMode"
          [(ngModel)]="contact.emailAddress"
          (ngModelChange)="onEmailAddressChange()"
          #emailAddressModel="ngModel"
          #emailAddressEl/>
      </div>
      <abp-validation-summary
        [control]="emailAddressModel"
        [controlEl]="emailAddressEl"
        [customValidationErrors]="emailValidationErrors">
      </abp-validation-summary>
    </div>
    <div class="input-label-cont extra form-grid-item" *ngIf="showMainPhone()">
      <label for="mainPhoneNumber">{{ "MainPhoneNumber" | localize }}</label>
      <div class="input-cont">
      <input
        type="text"
        class="input-txt"
        name="mainPhoneNumber"
        id="mainPhoneNumber"
        maxlength="64"
        [disabled]="isViewMode"
        [(ngModel)]="contact.mainPhoneNumber"
        (ngModelChange)="onMainPhoneNumberChange()"
        #mainPhoneNumberModel="ngModel"
        #mainPhoneNumberEl/>
      </div>
      <abp-validation-summary
        [control]="mainPhoneNumberModel"
        [controlEl]="mainPhoneNumberEl">
      </abp-validation-summary>
    </div>
    <div class="input-label-cont extra form-grid-item" *ngIf="showAlternatePhone()">
      <label for="alternatePhoneNumber">{{ "AlternatePhoneNumber" | localize }}</label>
      <div class="input-cont">
        <input
          type="text"
          class="input-txt"
          name="alternatePhoneNumber"
          id="alternatePhoneNumber"
          maxlength="64"
          validateNotEqual="mainPhoneNumber"
          [disabled]="isViewMode"
          [(ngModel)]="contact.alternatePhoneNumber"
          (ngModelChange)="onAlternatePhoneNumberChange()"
          #alternatePhoneNumberModel="ngModel"
          #alternatePhoneNumberEl/>
      </div>
      <abp-validation-summary
        [control]="alternatePhoneNumberModel"
        [controlEl]="alternatePhoneNumberEl"
        [customValidationErrors]="contactValidationErrors">
      </abp-validation-summary>
    </div>
    <div class="input-label-cont extra form-grid-item" *ngIf="showContactPreferences()">
      <label
        for="contactPreference"
        [ngClass]="{ required: !isViewMode }">
        {{ "ContactPreferences" | localize }}
      </label>
      <div class="input-cont icon-addon">
        <input
          id="contactPreference"
          name="contactPreference"
          type="text"
          class="input-txt"
          autocomplete="off"
          required
          validateContactPreference
          [disabled]="isViewMode"
          [(ngModel)]="contactPreferenceSearchField"
          typeaheadOptionField="localizationKeyName"
          [typeahead]="contactPreferences"
          typeaheadScrollable="true"
          [typeaheadMinLength]="0"
          [typeaheadOptionsLimit]="50"
          (typeaheadOnSelect)="onContactPreferenceSelected($event)"
          (focusout)="onContactPreferenceFocusOut()"
          validateInList
          [validateInListOptions]="contactPreferences"
          #contactPreferencesModel="ngModel"
          #contactPreferencesEl>
        <i class="fa fa-times pointer" style="font-size: 80%;" (click)="contactPreferenceSearchField = ''; contactPreferencesModel.control.markAsTouched();" *ngIf="contactPreferenceSearchField && !isViewMode"></i>
        <i class="fa fa-angle-down" style="font-size: 80%;" (click)="contactPreferencesEl.focus()" *ngIf="!contactPreferenceSearchField"></i>
      </div>
      <abp-validation-summary
        [control]="contactPreferencesModel"
        [controlEl]="contactPreferencesEl"
        [customValidationErrors]="contactValidationErrors">
      </abp-validation-summary>
    </div>
  </div>
 </form>