import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  Injector,
  OnInit,
  HostListener
} from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';
import { ModalStatusService } from '@shared/helpers/modal-status.service';
import {
  CompanyDto as ItemDto,
} from '@shared/service-proxies/service-proxies';

@Component({
  selector: 'abp-modal-header',
  templateUrl: './abp-modal-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbpModalHeaderComponent extends AppComponentBase implements OnInit {
  @Input() title: string;
  @Input() modalId: string = undefined;
  @Input() countryISOAlpha2Code: string;
  @Input() allowInnerHtml: boolean = false;
  @Input() hideMinimize: boolean = false;

  maximized: boolean = false;
  minimized: boolean = false;

  @Output() onCloseClick = new EventEmitter<number>();
  @Output() onMaximizeClick = new EventEmitter<boolean>();
  @Output() onMinimizeClick = new EventEmitter<boolean>();

  constructor(injector: Injector,
    private _modalStatusService: ModalStatusService) {
    super(injector);
  }

  ngOnInit(): void {
    this._modalStatusService.getMinimizedStatusObservable().subscribe(x => {
      if(this.hasSettedModalId()) {
        if(x.id == this.modalId && x.status == true) {
          this.expandRestoreIfMinimized(null);
        }
      }
    });
  }

  closeClick($event) {
    this.onCloseClick.emit();
    $event.stopPropagation();

    // Remove the modal reference when closing
    // the modal.
    if(this.hasSettedModalId()) {
      this._modalStatusService.removeMinimizedStatus(this.modalId, false);
    }
  }

  expandRestore($event) {
    if(this.minimized) {
      this.minimized = !this.minimized;
      this.onMinimizeClick.emit(this.minimized);
    }

    this.maximized = !this.maximized;
    this.onMaximizeClick.emit(this.maximized);
    $event.stopPropagation();
  }

  expandRestoreIfMinimized($event) {
    if(this.minimized) {
      this.minimized = !this.minimized;
      this.onMinimizeClick.emit(this.minimized);

      if(this.hasSettedModalId()) {
        this._modalStatusService.setMinimizedStatus(this.modalId, this.minimized, false);
      }
    }
  }

  minimizeRestore($event) {
    if(this.maximized) {
      this.maximized = !this.maximized;
      this.onMinimizeClick.emit(this.maximized);
    }

    this.minimized = !this.minimized;
    this.onMinimizeClick.emit(this.minimized);
    $event.stopPropagation();

    if(this.hasSettedModalId()) {
      this._modalStatusService.setMinimizedStatus(this.modalId, this.minimized, false);
    }
  }

  hasSettedModalId(): boolean {
    return this.modalId != null &&
    this.modalId != undefined &&
    this.modalId != '';
  }

  // @HostListener('window:click', ['$event'])
  // onBackdropClick(event: MouseEvent) {
  //   const target = event.target as HTMLElement;

  //   if (target.classList.contains('modal') && !this.minimized) {
  //     this.minimizeRestore(event);
  //   }
  // }

  @HostListener('window:keydown.escape', ['$event'])
  onEscapeKey(event: KeyboardEvent) {
    if (!this.minimized && !this.hideMinimize) {
      this.minimizeRestore(event);
    }
  }

  getCountryFlagSource(): string {
    return this.countryISOAlpha2Code ? `assets/img/flags/${this.countryISOAlpha2Code.toLowerCase()}.png` : '';
  }

  getHeaderContent(): string {
    const flagSrc = this.getCountryFlagSource();
    const formattedTitle = this.removeEditFromTitle(this.title);
    return `<img src="${flagSrc}" width="20" style="margin-right: 5px;"> ${formattedTitle}`;
  }

  removeEditFromTitle(text: string): string {
    const localizedEdit = this.l('Edit');
    return text
        .replace(new RegExp(localizedEdit, 'g'), '')
        .trim();
  }

}
