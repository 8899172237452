import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';
import { FieldTypeEnum, OrderDirectionEnum } from '@shared/AppEnums';
import * as moment from 'moment';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-orderable-table-column',
  templateUrl: './orderable-table-column.component.html',
  styleUrls: ['./orderable-table-column.component.css']
})
export class OrderableTableColumnComponent extends AppComponentBase implements OnInit {
  @ViewChild('template') template: TemplateRef<any>;
  @ViewChild('filterDropdown', { static: false }) myDropdown: BsDropdownDirective;

  @ViewChild('filterText') filterText: ElementRef;
  @ViewChild('filterNumber') filterNumber: ElementRef;
  @ViewChild('filterDate') filterDate: ElementRef;

  @Input() currentOrder: string = '';
  @Input() columnName: string = '';
  @Input() orderDirection: OrderDirectionEnum = OrderDirectionEnum.Ascending;

  @Input() filter: boolean = false;
  @Input() filterFieldType: FieldTypeEnum = FieldTypeEnum.String;
  @Input() headerStyle: string = '';
  filterValue: string = '';
  executedValue: string = '';
  isFilterExecutedWithValue: boolean = false;

  @Output() executeOrdering: EventEmitter<any> = new EventEmitter<any>();
  @Output() executeFiltering: EventEmitter<any> = new EventEmitter<any>();

  constructor(injector: Injector, private cdr: ChangeDetectorRef) {
    super(injector);
  }

  ngOnInit() {

  }

  order(): void {
    this.executeOrdering.emit();
  }

  // FILTER
  executeFilter() {
    const stringFilterValue = this.getStringInputValue();
    this.isFilterExecutedWithValue = this.filterValue?.toString().length > 0;
    this.executedValue = this.filterValue;
    this.executeFiltering.emit(stringFilterValue);
    this.closeFilterDropdown();
  }

  onEnterPress(event: KeyboardEvent, value: string) {
    event.preventDefault();
    event.stopPropagation(); 
    this.filterValue = value.trim(); 
    this.cdr.detectChanges();
    setTimeout(() => {
      this.executeFilter();
    }, 0);
  }

  clearFilter() {
    this.filterValue = '';
    this.executeFilter();
  }

  clearAndCloseFilterDropdown() {
    if(!this.isFilterExecutedWithValue) { this.filterValue = ''; }
    else { this.filterValue = this.executedValue; }

    this.closeFilterDropdown();
  }

  closeFilterDropdown() {
    if (this.myDropdown.isOpen) {
      this.myDropdown.hide();
    }
  }

  onOpenChange(data: boolean): void {
    if(data) {
      setTimeout(() => this.setFocusOnCorrectInput(), 100);
    } else {
      this.clearAndCloseFilterDropdown();
    }
  }

  setFocusOnCorrectInput() {
    switch(this.filterFieldType) {
      case FieldTypeEnum.Int:
      case FieldTypeEnum.Decimal:
        this.filterNumber.nativeElement.focus();
        break;
      case FieldTypeEnum.Date:
        this.filterDate.nativeElement.focus();
        break;
      case FieldTypeEnum.String:
      default:
        this.filterText.nativeElement.focus();
        break;
    }
  }

  mustShowTextInput(): boolean {
    return this.filterFieldType == FieldTypeEnum.String;
  }

  mustShowNumberInput(): boolean {
    return this.filterFieldType == FieldTypeEnum.Int ||
    this.filterFieldType == FieldTypeEnum.Decimal;
  }

  mustShowDateInput(): boolean {
    return this.filterFieldType == FieldTypeEnum.Date;
  }

  getStringInputValue(): string {
    return this.filterFieldType == FieldTypeEnum.Date ?
      moment(new Date(this.filterValue)).format('YYYY-MM-DD') :
      this.filterValue.toString();
  }
}
