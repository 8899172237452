import { Component, Injector, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import enLocale from '@fullcalendar/core/locales/en-gb';
import ptLocale from '@fullcalendar/core/locales/pt';
import { AppComponentBase } from '@shared/app-component-base';

@Component({
  selector: 'app-right-calendar',
  templateUrl: './right-calendar.component.html',
  styleUrls: ['./right-calendar.component.css']
})
export class RightCalendarComponent extends AppComponentBase implements OnInit {
  //@ViewChild('calendarContainer', { read: ViewContainerRef }) calendarContainer: ViewContainerRef;
  //calendar: any;
  //dateSelected: Date;

  //calendarOptions: CalendarOptions = {
  //  initialView: 'dayGridMonth',
  //  plugins: [ dayGridPlugin, interactionPlugin ],
  //  weekends: true,
  //  headerToolbar: {
  //    left: 'title',
  //    right: 'prev,next'
  //  },
  //  dateClick: (info) => {
  //    this.dateSelected = info.date;
  //    this.router.navigate(['/app/user-diary', this.formatDate(this.dateSelected)]);
  //  },
  //  locale: this.localization.currentLanguage.name == 'es' ? esLocale : this.localization.//currentLanguage.name == 'pt' ? ptLocale : enLocale
  //};
  
  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    //this.loadCalendar();
  }

  //formatDate(date: Date): string {
  //  const year = date.getFullYear();
  //  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  //  const day = date.getDate().toString().padStart(2, '0');
  //  return `${year}-${month}-${day}`;
  //}

  //loadCalendar() {
  //  if(!this.calendar) {
  //    setTimeout(() => {
  //      this.calendarContainer.clear();
  //      this.calendar = this.calendarContainer.createComponent(FullCalendarComponent);
  //      this.calendar.instance.options = this.calendarOptions;
  //    }, 0);
  //  }
  //}
}
