<div class="right-menu">
  <div class="top-right-menu">
    <div *ngIf="isTenant()">
      <!--<ng-template #popCalendar>
        <div>
          <app-right-calendar></app-right-calendar>
        </div>
      </ng-template>-->
      <input
      [placeholder]="getDateInputFormat()"
      bsDatepicker
      [id]="'calendar'"
      [name]="'calendar'"
      placement="left"
      class="right-menu-calendar"
      [bsConfig]="{ isAnimated: false }"
      [daysDisabled]="[0,1,2,3,4,5,6]"
      #dp="bsDatepicker"
    />
      <i
        class="material-icons-outlined"
        placement="left"
        style="margin-top: 10px"
        [tooltip]="'Calendar' | localize"
        [adaptivePosition]="false"
        placement="left"
        (click)="dp.toggle()"
      >
        today
      </i>
      <ng-template #popTemplate>
        <div>
          <app-notifications></app-notifications>
        </div>
      </ng-template>
      <small
        class="bg-danger rounded-circle-noti"
        [class.disabled]="numberNotifications <= 0"
      >
        {{ numberNotifications }}
      </small>
      <i
        class="material-icons-outlined"
        #notificationIcon
        [popover]="popTemplate"
        placement="left"
        (click)="togglePopover()"
        #notificationPopover="bs-popover"
        [tooltip]="'Notifications' | localize"
        adaptivePosition="true"
        placement="right"
        [outsideClick]="true"
      >
      notifications
    </i>
    </div>
  </div>
  <div class="bottom-right-menu">
    <i
      class="material-icons-outlined"
      (click)="redirectUserConfiguration()"
      [tooltip]="'UserPreferences' | localize"
      [containerClass]="'user-preferences-tooltip'"
      placement="right"
    >
      manage_accounts
    </i>
    <i
      class="material-icons-outlined"
      (click)="logout()"
      [tooltip]="'Logout' | localize"
      adaptivePosition="true"
      placement="right"
    >
      power_settings_new
    </i>
  </div>
</div>
