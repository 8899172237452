import { Directive, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';

@Directive({
    selector: '[autofocus]',
})
export class AutofocusDirective implements AfterViewInit {
    constructor(private el: ElementRef, private cdRef: ChangeDetectorRef) { }

    ngAfterViewInit() {
        this.cdRef.detectChanges();
        setTimeout(() => {
            this.el.nativeElement.focus();
        }, 0);
    }
}