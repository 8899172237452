import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common'

@Pipe({
    name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {

    constructor(private cp: CurrencyPipe) {
    }

    transform(value: number, currencyCode: string): string {
        // Verifica si el valor tiene decimales y si estos son 0
        const hasDecimals = value % 1 !== 0;
        const decimalValue = parseFloat(value.toFixed(2)).toString();

        // Define el formato condicionalmente, con el símbolo de la moneda al frente
        const formattedValue = hasDecimals && decimalValue.split('.')[1] !== '00'
            ? this.cp.transform(value, '', '', '1.2-2') // Con decimales
            : this.cp.transform(value, '', '', '1.0-0'); // Sin decimales

        return `${currencyCode} ${formattedValue}`;

    }
}
