<form class="form-horizontal" autocomplete="off" #form="ngForm">
  <div class="form-grid">
    <!-- Country -->
    <div class="input-label-cont extra form-grid-item" *ngIf="!editMode || isViewMode">
      <label for="countryDD">{{ "Country" | localize }}</label>
      <div class="input-cont">
        <div class="w-100">{{ getCountryName(address) }}</div>
      </div>
    </div>
    <div class="input-label-cont extra form-grid-item" *ngIf="editMode && !isViewMode">
      <label [ngClass]="addressOriginal && addressOriginal?.countryId != address?.countryId ? 'suggested' : ''" for="country">{{ "Country" | localize }}</label>
      <div class="input-cont icon-addon" [ngClass]="{ 'empty-field': !isCreating && editMode && !shouldDisableValue('country') }">
        <input
          id="country"
          name="country"
          type="text"
          class="input-txt"
          [tooltip]="addressOriginal && addressOriginal?.countryId != address?.countryId ? getCountryName(addressOriginal) : ''"
          autocomplete="off"
          [(ngModel)]="countrySearchField"
          typeaheadOptionField="localizationKeyName"
          [typeahead]="countries"
          typeaheadScrollable="true"
          [typeaheadMinLength]="0"
          [typeaheadOptionsLimit]="50"
          (typeaheadOnSelect)="onCountrySelected($event)"
          (focusout)="onCountryFocusOut()"
          validateInList
          [validateInListOptions]="countries"
          #countryModel="ngModel"
          #countryEl>
        <i class="fa fa-times pointer" style="font-size: 80%;" (click)="onCountryDelete()" *ngIf="countrySearchField && !shouldDisableValue('countryId')"></i>
        <i class="fa fa-angle-down" style="font-size: 80%;" (click)="countryEl.focus()" *ngIf="!countrySearchField"></i>
      </div>
      <abp-validation-summary
        [control]="countryModel"
        [controlEl]="countryEl">
      </abp-validation-summary>
    </div>
    <!-- Location -->
    <div class="input-label-cont extra form-grid-item" *ngIf="!editMode || isViewMode">
      <label>{{ "Location" | localize }}</label>
      <div class="input-cont">
        <div class="w-100" *ngIf="address?.countryId == uruguayCountryId">{{ getLocationName(address) }}&nbsp;</div>
        <div class="w-100" *ngIf="address?.countryId != uruguayCountryId">{{ address?.locationText }}&nbsp;</div>
      </div>
    </div>
    <div class="input-label-cont extra form-grid-item" *ngIf="editMode && !isViewMode && address?.countryId == uruguayCountryId">
      <label [ngClass]="addressOriginal && addressOriginal?.locationId != address?.locationId ? 'suggested' : ''" for="location">{{ "Location" | localize }}</label>
      <div class="input-cont icon-addon" [ngClass]="{ 'empty-field': !isCreating && editMode && !shouldDisableValue('location') }">
        <input
          id="location"
          name="location"
          type="text"
          class="input-txt"
          [tooltip]="addressOriginal && addressOriginal?.locationId != address?.locationId ? getLocationName(addressOriginal) : ''"
          autocomplete="off"
          [(ngModel)]="locationSearchField"
          typeaheadOptionField="localizationKeyName"
          [typeahead]="locations"
          typeaheadScrollable="true"
          [typeaheadMinLength]="0"
          [typeaheadOptionsLimit]="50"
          (typeaheadOnSelect)="onLocationSelected($event)"
          (focusout)="onLocationFocusOut()"
          validateInList
          [validateInListOptions]="locations"
          #locationModel="ngModel"
          #locationEl>
        <i class="fa fa-times pointer" style="font-size: 80%;" (click)="onLocationDelete()" *ngIf="locationSearchField && !shouldDisableValue('locationId')"></i>
        <i class="fa fa-angle-down" style="font-size: 80%;" (click)="locationEl.focus()" *ngIf="!locationSearchField"></i>
      </div>
      <abp-validation-summary
        [control]="locationModel"
        [controlEl]="locationEl">
      </abp-validation-summary>
    </div>
    <div class="input-label-cont extra form-grid-item" *ngIf="editMode && !isViewMode && address?.countryId != uruguayCountryId">
      <label [ngClass]="addressOriginal && addressOriginal?.locationText != address?.locationText ? 'suggested' : ''" for="locationText">{{ "Location" | localize }}</label>
      <div class="input-cont" [ngClass]="{ 'empty-field': !isCreating && editMode && !shouldDisableValue('locationText') }">
        <input
          type="text"
          class="input-txt"
          [tooltip]="addressOriginal && addressOriginal?.locationText != address?.locationText ? addressOriginal?.locationText : ''"
          name="locationText"
          id="locationText"
          maxlength="64"
          [(ngModel)]="address.locationText"
          (ngModelChange)="emitChanges()"
          #locationTextModel="ngModel"
          #locationTextEl/>
      </div>
      <abp-validation-summary
        [control]="locationTextModel"
        [controlEl]="locationTextEl">
      </abp-validation-summary>
    </div>
    <!-- Street -->
    <div class="input-label-cont extra form-grid-item" *ngIf="!editMode || isViewMode">
      <label for="street">{{ "StreetRoute" | localize }}</label>
      <div class="input-cont">
        <div>{{ address?.street }}&nbsp;</div>
      </div>
    </div>
    <div class="input-label-cont extra form-grid-item" *ngIf="editMode && !isViewMode">
      <label [ngClass]="addressOriginal && addressOriginal?.street != address?.street ? 'suggested' : ''" for="street">{{ "StreetRoute" | localize }}</label>
      <!-- <div class="input-cont" [ngClass]="{ 'empty-field': !isCreating && editMode && !shouldDisableValue('street') }"> -->
        <div class="input-cont" [ngClass]="{ 'empty-field': shouldShowEmptyField(address?.street) }">
        <input
          type="text"
          class="input-txt"
          [tooltip]="addressOriginal && addressOriginal?.street != address?.street ? addressOriginal?.street : ''"
          name="street"
          id="street"
          maxlength="64"
          [(ngModel)]="address.street"
          (ngModelChange)="emitChanges()"
          #streetModel="ngModel"
          #streetEl/>
      </div>
      <abp-validation-summary
        [control]="streetModel"
        [controlEl]="streetEl">
      </abp-validation-summary>
    </div>
    <!-- Number -->
    <div class="input-label-cont extra form-grid-item" *ngIf="!editMode || isViewMode">
      <label for="number">{{ "NumberKm" | localize }}</label>
      <div class="input-cont">
        <div>{{ address?.number }}&nbsp;</div>
      </div>
    </div>
    <div class="input-label-cont extra form-grid-item" *ngIf="editMode && !isViewMode">
      <label [ngClass]="addressOriginal && addressOriginal?.number != address?.number ? 'suggested' : ''" for="number">{{ "NumberKm" | localize }}</label>
      <div class="input-cont" [ngClass]="{ 'empty-field': !isCreating && editMode && !shouldDisableValue('number') }">
        <input
          type="text"
          class="input-txt"
          [tooltip]="addressOriginal && addressOriginal?.number != address?.number ? addressOriginal?.number : ''"
          name="number"
          id="number"
          maxlength="64"
          [(ngModel)]="address.number"
          (ngModelChange)="emitChanges()"
          [disabled]="isViewMode"
          #numberModel="ngModel"
          #numberEl />
      </div>
      <abp-validation-summary
        [control]="numberModel"
        [controlEl]="numberEl">
      </abp-validation-summary>
    </div>
    <!-- Apartment -->
    <div class="input-label-cont extra form-grid-item" *ngIf="!editMode || isViewMode">
      <label [ngClass]="addressOriginal && addressOriginal?.apartment != address?.apartment ? 'suggested' : ''" for="apartment">{{ "Apartment" | localize }}</label>
      <div class="input-cont">
        <div >{{ address?.apartment }}&nbsp;</div>
      </div>
    </div>
    <div class="input-label-cont extra form-grid-item" *ngIf="editMode && !isViewMode">
      <label [ngClass]="addressOriginal && addressOriginal?.apartment != address?.apartment ? 'suggested' : ''" for="apartment">{{ "Apartment" | localize }}</label>
      <div class="input-cont" [ngClass]="{ 'empty-field': !isCreating && editMode && !shouldDisableValue('apartment') }">
        <input
          type="text"
          class="input-txt"
          [tooltip]="addressOriginal && addressOriginal?.apartment != address?.apartment ? addressOriginal?.apartment : ''"
          name="apartment"
          id="apartment"
          maxlength="64"
          [(ngModel)]="address.apartment"
          (ngModelChange)="emitChanges()" />
      </div>
    </div>
    <!-- Other -->
    <div class="input-label-cont extra form-grid-item" *ngIf="!editMode || isViewMode">
      <label [ngClass]="addressOriginal && addressOriginal?.others != address.others ? 'suggested' : ''" for="other">
        {{ "OtherLocation" | localize }}
      </label>
      <div class="input-cont" >{{ address?.others }}&nbsp;</div>
    </div>
    <div class="input-label-cont extra form-grid-item" *ngIf="editMode && !isViewMode">
      <label [ngClass]="addressOriginal && addressOriginal?.others != address.others ? 'suggested' : ''" for="other">
        {{ "OtherLocation" | localize }}
      </label>
      <!-- <div class="input-cont" [ngClass]="{ 'empty-field': !isCreating && editMode && (address?.others === undefined || address?.others === null || address?.others === '') }"> -->
      <div class="input-cont" [ngClass]="{ 'empty-field': !isCreating && editMode && !shouldDisableValue('others') }">
        <input
          type="text"
          class="input-txt"
          [tooltip]="addressOriginal && addressOriginal?.others != address.others ? addressOriginal?.others : ''"
          name="other"
          id="other"
          maxlength="64"
          [(ngModel)]="address.others"
          (ngModelChange)="emitChanges()" />
      </div>
    </div>
  </div>
</form>