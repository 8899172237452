import { Injectable } from "@angular/core";
import { AppConsts } from "@shared/AppConsts";
import {
  CompanyDto,
  CompanyRoleDto,
  CompanyServiceProxy,
  CompanyRoleServiceProxy,
  DocumentTypeServiceProxy,
  DocumentTypeDto,
  NationalityServiceProxy,
  CountryServiceProxy,
  OccupationServiceProxy,
  MaritalStatusServiceProxy,
  NuptialsServiceProxy,
  MarriageRegimeServiceProxy,
  WorkActivityServiceProxy,
  NationalityDto,
  CountryDto,
  OccupationDto,
  MaritalStatusDto,
  NuptialsDto,
  MarriageRegimeDto,
  LocationDto,
  LocationServiceProxy,
  WorkActivityDto,
  CurrencyDto,
  CurrencyServiceProxy,
  CompanyTypeServiceProxy,
  CompanyTypeDto,
  ShareTypeServiceProxy,
  ShareTypeDto,
  FunctionalLinkServiceProxy,
  HealthInsuranceServiceProxy,
  PositionServiceProxy,
  FunctionalLinkDto,
  HealthInsuranceDto,
  PositionDto,
  AttorneyInFactTypeDto,
  AttorneyInFactTypeServiceProxy,
} from '@shared/service-proxies/service-proxies';
import { Observable, mergeMap, of, tap } from "rxjs";
import { CacheService } from "./cache.service";
import { LocalizationService } from "abp-ng2-module";

@Injectable()
export class CatalogsLoader {
  localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;
  ttl: number = 60;

  constructor(
    private _localization: LocalizationService,
    private _cache: CacheService,
    private _companyService : CompanyServiceProxy,
    private _companyRoleService : CompanyRoleServiceProxy,
    private _companyTypeService: CompanyTypeServiceProxy,
    private _countryService: CountryServiceProxy,
    private _currencyService: CurrencyServiceProxy,
    private _documentTypeService: DocumentTypeServiceProxy,
    private _functionalLinkService: FunctionalLinkServiceProxy,
    private _healthInsuranceService: HealthInsuranceServiceProxy,
    private _attorneyInFactTypeService: AttorneyInFactTypeServiceProxy,
    private _locationsService: LocationServiceProxy,
    private _maritalStatusService: MaritalStatusServiceProxy,
    private _marriageRegimeService: MarriageRegimeServiceProxy,
    private _nationalityService: NationalityServiceProxy,
    private _nuptialsService: NuptialsServiceProxy,
    private _occupationService: OccupationServiceProxy,
    private _positionService: PositionServiceProxy,
    private _shareTypeService: ShareTypeServiceProxy,
    private _workActivityService: WorkActivityServiceProxy) {
  }

  loadCompanies(managedCompanies: boolean | undefined, juridicalPersonId: number | undefined, userMode: boolean | undefined): Observable<CompanyDto[]> {
      if (this._cache.has("companies") && false) {
      return of(this._cache.get("companies"));
    } else {
      if (userMode) {
        return this._companyService
        .getAllUsersCompanies(
          undefined,
          managedCompanies,
          juridicalPersonId,
          undefined,
          undefined,
          '',
          [],
          undefined,
          AppConsts.maxResultsCount)
        .pipe(mergeMap((result) => {
          return of(result.items);
        }))
        .pipe(tap(data => this._cache.set("companies", data, this.ttl)));
      }
      else {
        return this._companyService
        .getAll(
          undefined,
          managedCompanies,
          juridicalPersonId,
          undefined,
          undefined,
          '',
          [],
          undefined,
          AppConsts.maxResultsCount)
        .pipe(mergeMap((result) => {
          return of(result.items);
        }))
        .pipe(tap(data => this._cache.set("companies", data, this.ttl)));
      }
    }
  }

  loadCompanyRoles(): Observable<CompanyRoleDto[]> {
    if(this._cache.has("companyroles")) {
      return of(this._cache.get("companyroles"));
    } else {
      return this._companyRoleService
        .getAll(undefined, undefined, '', [], undefined, AppConsts.maxResultsCount)
        .pipe(mergeMap((result) => {
          result.items.forEach(x => x.localizationKeyName = this.l(x.localizationKeyName));
          return of(result.items.sort((a, b) => a.localizationKeyName.localeCompare(b.localizationKeyName)));
        }))
        .pipe(tap(data => this._cache.set("companyroles", data, this.ttl)));
    }
  }

  loadCompanyTypes(): Observable<CompanyTypeDto[]> {
    if(this._cache.has("companytypes")) {
      return of(this._cache.get("companytypes"));
    } else {
      return this._companyTypeService
        .getAll(undefined, undefined, '', [], undefined, AppConsts.maxResultsCount)
        .pipe(mergeMap((result) => {
          result.items.forEach(x => x.localizationKeyName = this.l(x.localizationKeyName));
          return of(result.items.sort((a, b) => a.localizationKeyName.localeCompare(b.localizationKeyName)));
        }))
        .pipe(tap(data => this._cache.set("companytypes", data, this.ttl)));
    }
  }

  loadCountries(): Observable<CountryDto[]> {
    if(this._cache.has("countries")) {
      return of(this._cache.get("countries"));
    } else {
      return this._countryService
        .getAll(undefined, undefined, '', [], undefined, AppConsts.maxResultsCount)
        .pipe(mergeMap((result) => {
          result.items.forEach(x => x.localizationKeyName = this.l(x.localizationKeyName));
          return of(result.items.sort((a, b) => a.localizationKeyName?.localeCompare(b.localizationKeyName)));
        }))
        .pipe(tap(data => this._cache.set("countries", data, this.ttl)));
    }
  }

  loadCurrencies(): Observable<CurrencyDto[]> {
    if(this._cache.has("currencies")) {
      return of(this._cache.get("currencies"));
    } else {
      return this._currencyService
        .getAll(undefined, undefined, '', [], undefined, AppConsts.maxResultsCount)
        .pipe(mergeMap((result) => {
          result.items.forEach(x => x.localizationKeyName = this.l(x.localizationKeyName));
          return of(result.items.sort((a, b) => a.localizationKeyName.localeCompare(b.localizationKeyName)));
        }))
        .pipe(tap(data => this._cache.set("currencies", data, this.ttl)));
    }
  }

  loadDocumentTypes(): Observable<DocumentTypeDto[]> {
    if(this._cache.has("documenttypes")) {
      return of(this._cache.get("documenttypes"));
    } else {
      return this._documentTypeService
        .getAll(undefined, undefined, '', [], undefined, AppConsts.maxResultsCount)
        .pipe(mergeMap((result) => {
          result.items.forEach(x => x.localizationKeyName = this.l(x.localizationKeyName));
          return of(result.items.sort((a, b) => a.localizationKeyName.localeCompare(b.localizationKeyName)));
        }))
        .pipe(tap(data => this._cache.set("documenttypes", data, this.ttl)));
    }
  }

  loadFunctionalLinks(): Observable<FunctionalLinkDto[]> {
    if(this._cache.has("functionallinks")) {
      return of(this._cache.get("functionallinks"));
    } else {
      return this._functionalLinkService
        .getAll(undefined, undefined, '', [], undefined, AppConsts.maxResultsCount)
        .pipe(mergeMap((result) => {
          result.items.forEach(x => x.localizationKeyName = this.l(x.localizationKeyName));
          return of(result.items.sort((a, b) => a.localizationKeyName.localeCompare(b.localizationKeyName)));
        }))
        .pipe(tap(data => this._cache.set("functionallinks", data, this.ttl)));
    }
  }

  loadHealthInsurances(): Observable<HealthInsuranceDto[]> {
    if(this._cache.has("healthinsurances")) {
      return of(this._cache.get("healthinsurances"));
    } else {
      return this._healthInsuranceService
        .getAll(undefined, undefined, '', [], undefined, AppConsts.maxResultsCount)
        .pipe(mergeMap((result) => {
          result.items.forEach(x => x.localizationKeyName = this.l(x.localizationKeyName));
          return of(result.items.sort((a, b) => a.localizationKeyName.localeCompare(b.localizationKeyName)));
        }))
        .pipe(tap(data => this._cache.set("healthinsurances", data, this.ttl)));
    }
  }

  loadAttorneyInFactTypes(): Observable<AttorneyInFactTypeDto[]> {
    if(this._cache.has("attorneyinfacttypes")) {
      return of(this._cache.get("attorneyinfacttypes"));
    } else {
      return this._attorneyInFactTypeService
        .getAll(undefined, undefined, '', [], undefined, AppConsts.maxResultsCount)
        .pipe(mergeMap((result) => {
          result.items.forEach(x => x.localizationKeyName = this.l(x.localizationKeyName));
          return of(result.items.sort((a, b) => a.localizationKeyName.localeCompare(b.localizationKeyName)));
        }))
        .pipe(tap(data => this._cache.set("attorneyinfacttypes", data, this.ttl)));
    }
  }

  loadLocations(countryId: number): Observable<LocationDto[]> {
    return this._locationsService
      .getAll(countryId, undefined, undefined, '', [], undefined, AppConsts.maxResultsCount)
      .pipe(mergeMap((result) => {
        result.items.forEach(x => x.localizationKeyName = this.l(x.localizationKeyName));
        return of(result.items.sort((a, b) => a.localizationKeyName.localeCompare(b.localizationKeyName)));
      }));
  }

  loadMaritalStatuses(): Observable<MaritalStatusDto[]> {
    if(this._cache.has("maritalstatuses")) {
      return of(this._cache.get("maritalstatuses"));
    } else {
      return this._maritalStatusService
        .getAll(undefined, undefined, '', [], undefined, AppConsts.maxResultsCount)
        .pipe(mergeMap((result) => {
          result.items.forEach(x => x.localizationKeyName = this.l(x.localizationKeyName));
          return of(result.items.sort((a, b) => a.localizationKeyName.localeCompare(b.localizationKeyName)));
        }))
        .pipe(tap(data => this._cache.set("maritalstatuses", data, this.ttl)));
    }
  }

  loadMarriageRegimes(): Observable<MarriageRegimeDto[]> {
    if(this._cache.has("marriageregimes")) {
      return of(this._cache.get("marriageregimes"));
    } else {
      return this._marriageRegimeService
        .getAll(undefined, undefined, '', [], undefined, AppConsts.maxResultsCount)
        .pipe(mergeMap((result) => {
          result.items.forEach(x => x.localizationKeyName = this.l(x.localizationKeyName));
          return of(result.items.sort((a, b) => a.localizationKeyName.localeCompare(b.localizationKeyName)));
        }))
        .pipe(tap(data => this._cache.set("marriageregimes", data, this.ttl)));
    }
  }

  loadNationalities(): Observable<NationalityDto[]> {
    if(this._cache.has("nationalities")) {
      return of(this._cache.get("nationalities"));
    } else {
      return this._nationalityService
        .getAll(undefined, undefined, '', [], undefined, AppConsts.maxResultsCount)
        .pipe(mergeMap((result) => {
          result.items.forEach(x => x.localizationKeyName = this.l(x.localizationKeyName));
          return of(result.items.sort((a, b) => a.localizationKeyName.localeCompare(b.localizationKeyName)));
        }))
        .pipe(tap(data => this._cache.set("nationalities", data, this.ttl)));
    }
  }

  loadNuptials(): Observable<NuptialsDto[]> {
    if(this._cache.has("nuptials")) {
      return of(this._cache.get("nuptials"));
    } else {
      return this._nuptialsService
        .getAll(undefined, undefined, '', [], undefined, AppConsts.maxResultsCount)
        .pipe(mergeMap((result) => {
          result.items.forEach(x => x.localizationKeyName = this.l(x.localizationKeyName));
          return of(result.items.sort((a, b) => a.localizationKeyName.localeCompare(b.localizationKeyName)));
        }))
        .pipe(tap(data => this._cache.set("nuptials", data, this.ttl)));
    }
  }

  loadOccupations(): Observable<OccupationDto[]> {
    if(this._cache.has("occupations")) {
      return of(this._cache.get("occupations"));
    } else {
      return this._occupationService
        .getAll(undefined, undefined, '', [], undefined, AppConsts.maxResultsCount)
        .pipe(mergeMap((result) => {
          result.items.forEach(x => x.localizationKeyName = this.l(x.localizationKeyName));
          result.items.sort((a, b) => a.localizationKeyName.localeCompare(b.localizationKeyName))
          const indexToMove = result.items.findIndex(item => item.localizationKeyName === this.l(AppConsts.occupationOther));
          if (indexToMove !== -1) {
              const [itemToMove] = result.items.splice(indexToMove, 1);
              result.items.unshift(itemToMove);
          }
          return of(result.items);
        }))
        .pipe(tap(data => this._cache.set("occupations", data, this.ttl)));
    }
  }

  loadPositions(): Observable<PositionDto[]> {
    if(this._cache.has("positions")) {
      return of(this._cache.get("positions"));
    } else {
      return this._positionService
        .getAll(undefined, undefined, '', [], undefined, AppConsts.maxResultsCount)
        .pipe(mergeMap((result) => {
          result.items.forEach(x => x.localizationKeyName = this.l(x.localizationKeyName));
          return of(result.items.sort((a, b) => a.localizationKeyName.localeCompare(b.localizationKeyName)));
        }))
        .pipe(tap(data => this._cache.set("positions", data, this.ttl)));
    }
  }

  loadShareTypes(): Observable<ShareTypeDto[]> {
    if(this._cache.has("sharetypes")) {
      return of(this._cache.get("sharetypes"));
    } else {
      return this._shareTypeService
        .getAll(undefined, undefined, '', [], undefined, AppConsts.maxResultsCount)
        .pipe(mergeMap((result) => {
          result.items.forEach(x => x.localizationKeyName = this.l(x.localizationKeyName));
          return of(result.items.sort((a, b) => a.localizationKeyName.localeCompare(b.localizationKeyName)));
        }))
        .pipe(tap(data => this._cache.set("sharetypes", data, this.ttl)));
    }
  }

  loadWorkActivities(): Observable<WorkActivityDto[]> {
    if(this._cache.has("workactivities")) {
      return of(this._cache.get("workactivities"));
    } else {
      return this._workActivityService
        .getAll(undefined, undefined, '', [], undefined, AppConsts.maxResultsCount)
        .pipe(mergeMap((result) => {
          result.items.forEach(x => x.localizationKeyName = this.l(x.localizationKeyName));
          result.items.sort((a, b) => a.localizationKeyName.localeCompare(b.localizationKeyName))
          const indexToMove = result.items.findIndex(item => item.localizationKeyName === this.l(AppConsts.otherWorkActivity));
          if (indexToMove !== -1) {
              const [itemToMove] = result.items.splice(indexToMove, 1);
              result.items.push(itemToMove);
          }
          return of(result.items);
        }))
        .pipe(tap(data => this._cache.set("workactivities", data, this.ttl)));
    }
  }

  l(key: string, ...args: any[]): string {
    let localizedText = this._localization.localize(key, this.localizationSourceName);

    if (!localizedText) {
      localizedText = key;
    }

    if (!args || !args.length) {
      return localizedText;
    }

    args.unshift(localizedText);
    return abp.utils.formatString.apply(this, args);
  }
}
