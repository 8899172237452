import { Attribute, Directive, forwardRef, Injector } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { AppComponentBase } from '@shared/app-component-base';
import * as moment from 'moment';

@Directive({
  selector: '[validateGreaterThanZero][ngModel],[validateGreaterThanZero][formControl]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => GreaterThanZeroValidator),
      multi: true
    }
  ]
})
export class GreaterThanZeroValidator extends AppComponentBase implements Validator {
  constructor(injector: Injector) {
    super(injector);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (value == undefined || value == null) {
      return null;
    }

    return value <= 0 ? { validateGreaterThanZero: true } : null;
  }
}
