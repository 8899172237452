import { Attribute, Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[equalOrLowerValue][ngModel],[equalOrLowerValue][formControl]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EqualOrLowerValueValidator),
      multi: true
    }
  ]
})
export class EqualOrLowerValueValidator implements Validator {
  constructor(@Attribute('equalOrLowerValue') public value: number) {}

  validate(control: AbstractControl): ValidationErrors | null {
    const currentValueControl = control;
    const valueControl = control.root.get(this.value.toString());

    if (!currentValueControl || !valueControl) {
      return null;
    }

    const currentValue = this.parseNumber(currentValueControl.value);
    const value = this.parseNumber(valueControl.value);

    if (currentValue !== null && value !== null && currentValue > value) {
      return { equalOrLowerValue: true };
    }

    return null;
  }

  private parseNumber(value: string): number | null {
    if (!value) {
      return null;
    }

    if (value.includes(',')) {
      const parts = value.split(',');

      if (parts.length > 2) {
        return null;
      }

      let normalizedValue = parts[0].replace(/\./g, '');
      normalizedValue += '.' + parts[1];
      return parseFloat(normalizedValue);
    }

    const normalizedValue = value.replace(/\./g, '');
    const parsedValue = parseFloat(normalizedValue);
    return isNaN(parsedValue) ? null : parsedValue;
  }
}
