import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';
import { NotificationCountUpdatedService } from '@shared/helpers/notification-count-updated.service';
import { BaseMessageNotificationDataDto, NotificationServiceProxy } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { Moment } from 'moment';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent extends AppComponentBase implements OnInit {
  notificationsList: BaseMessageNotificationDataDto[];
  dropdownOpenId: string | null = null;

  constructor(
    injector: Injector,
    private _notificationService: NotificationServiceProxy,
    private _notificationCountUpdatedService: NotificationCountUpdatedService,
    private cdr: ChangeDetectorRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this._notificationService
    .getNotificationsInfo()
    .subscribe((result: BaseMessageNotificationDataDto[]) => {
      this.notificationsList = result;
      this.cdr.detectChanges();
    });
  }

  setNotificationAsRead(notificationId: string): void {
    this._notificationService
    .setNotificationAsRead(notificationId)
    .subscribe({
      next: () => {
        this.notificationsList = [];
        this._notificationCountUpdatedService.setNewNotificationRefresh(true);
        this.ngOnInit();
      }
    });
  }

  setNotificationAsArchived(notificationId: string): void {
    this._notificationService
    .setNotificationAsArchived(notificationId)
    .subscribe({
      next: () => {
        this.notificationsList = [];
        this._notificationCountUpdatedService.setNewNotificationRefresh(true);
        this.ngOnInit();
      }
    });
  }

  setAllNotificationsAsRead(): void {
    this._notificationService
    .setAllNotificationsAsArchivated()
    .subscribe({
      next: () => {
        this.notificationsList = [];
        this._notificationCountUpdatedService.setNewNotificationRefresh(true);
        this.cdr.detectChanges();
      }
    });
  }

  timeSince(notificationDate: Moment): string {
    const now = moment();
    const duration = moment.duration(now.diff(notificationDate));

    const days = Math.floor(duration.asDays());
    const hours = Math.floor(duration.asHours() % 24);
    const minutes = Math.floor(duration.asMinutes() % 60);
    const seconds = Math.floor(duration.asSeconds() % 60);

    if (days > 0) {
        const dayText = this.l('day');
        const daysText = this.l('days');
        return days === 1 ? `1 ` + dayText : `${days} ` + daysText;
    } else if (hours > 0) {
        const hourText = this.l('hour');
        const hoursText = this.l('hours');
        return hours === 1 ? `1 ` + hourText : `${hours} ` + hoursText;
    } else if (minutes > 0) {
        const minuteText = this.l('minute');
        const minutesText = this.l('minutes');
        return minutes === 1 ? `1 ` + minuteText : `${minutes} ` + minutesText;
    } else {
        const secondText = this.l('second');
        const secondsText = this.l('seconds');
        return seconds === 1 ? `1 ` + secondText : `${seconds} ` + secondsText;
    }
  }

  toggleDropdown(notificationId: string) {
    if (this.dropdownOpenId === notificationId) {
      this.dropdownOpenId = null;
    } else {
      this.dropdownOpenId = notificationId;
    }
  }

  isDropdownOpen(notificationId: string): boolean {
    return this.dropdownOpenId === notificationId;
  }

  navigateToComponent(): Promise<boolean> {
    return this.router.navigateByUrl('/app/user-diary');
  }
}
